import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudymdlive.scss';

import imgQuote from "../images/quotation_mark.webp";

// import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFullTwo extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: this.props.heightTwo,
            backgroundColor: this.props.backgroundColor
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12 bgImg" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="case-study-two-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <img src={pageContext.acf.client_logo.source_url} className="client-logo" width={pageContext.acf.client_logo.width} height={pageContext.acf.client_logo.height} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="first-paragraph">
                            <p>MDLIVE is a leading telemedicine provider that offers patients, health plans, health systems and self-insured employers access to board-certified doctors, pediatricians and licensed therapists. Consultations are performed via online video, phone or mobile app.</p>
                        </div>
                    </div>
                    <div className="col-12">
                    </div>
                </div>
            </div>
            <div className="container single-image-text-wrapper detail-wrapper">
                <div className="row">
                    <div className="col-md-6 order-last">
                        <img src="https://www.meritmile.com/uploads/2019/05/display-landing-page@2x.jpg" class="img-fluid" width="539" height="450" alt="" />
                    </div>
                    <div className="col-md-6 text">
                        <div className="text">
                            <h3>Challenge</h3>
                            <p>No longer future-forward concepts, telemedicine and virtual care have experienced significant growth and have evolved into mainstay methods of healthcare delivery. Firmly entrenched as a marketplace leader with roughly 30 million members, MDLIVE wanted to penetrate key market segments—including nationwide health systems and health plans—for broader awareness, lead-generation and revenue-driven marketing communications. What’s more, company leadership needed these efforts to land internally in time for their annual sales conference, and then externally ahead of the foremost healthcare industry trade show, HIMSS 2019.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container single-image-text-wrapper detail-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://www.meritmile.com/uploads/2019/05/tablet@2x.jpg" class="img-fluid" width="349" height="476" alt="" />
                    </div>
                    <div className="col-md-6 text">
                        <div className="text">
                            <h3>Solutions</h3>
                            <p>Merit Mile proposed three integrated campaigns where MDLIVE could leverage existing marketing assets such as white papers and their Salesforce (SFDC) + Pardot CRM environment. The campaigns targeted executive leadership within nationwide health plans, health systems, Medicare and Medicaid market segments.</p>
                            <p>A third campaign was developed as a “win-back” campaign designed to reinvigorate providers that may have partnered with the competition or required additional nurturing during the sales cycle.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <h3>Results</h3>
                            <p>The three campaigns were fully concepted and ready for execution in approximately 8 business weeks. The messaging and creative were designed to communicate and differentiate MDLIVE over their competitors as the leading provider of telehealth services for health systems and health plans. Ultimately, clean design, compelling visuals, and clear benefit messaging yielded qualified form submission leads from MDLIVE email lists and MDLIVE paid media banner placements. As of Q2 2019 the campaigns are scheduled for launch with programmatic A/B testing for optimal conversions and ROI.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container single-image-text-wrapper detail-wrapper">
                <div className="row">
                    <div className="col-md-6 order-last">
                        <img src="https://www.meritmile.com/uploads/2019/05/phone-tablet@2x.jpg" className="img-fluid" width="540" height="416" alt="" />
                    </div>
                    <div className="col-md-6 text">
                        <div className="text">
                            <h3>Deliverables</h3>
                            <ul>
                                <li>Buyer persona analysis</li>
                                <li>Messaging pillars development—primary and secondary</li>
                                <li>Creative concepting and copywriting</li>
                                <li>Tactical production of emails, landing<br />
                                pages, static and rich-media banners</li>
                                <li>Back-end CRM integration of Salesforce (SFDC) + Pardot</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <OneImageFullTwo width={pageContext.childWordPressAcf1ImageFull.image.media_details.width/2} heightTwo={pageContext.childWordPressAcf1ImageFull.image.media_details.height/2} imageOne={pageContext.childWordPressAcf1ImageFull.image.source_url} backgroundColor={pageContext.childWordPressAcf1ImageFull.image_full_bg_color} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 website-btn text-center">
                        <a href="https://www.mdlive.com/" className="btn sitebtn" target="_blank" rel="noopener noreferrer">View Website</a>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="text">
                            <p>"Individual campaigns can create an integrated solution"</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
)